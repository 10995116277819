import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  faChartLine,
  faCheck,
  faFileContract,
  faFileInvoiceDollar,
  faRobot,
  faTasks,
  faUser,
  faStore,
  faCalendar,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col xs={12} className="d-flex my-3 justify-content-center">
            <h1>
              {t('App.backoffice')}
            </h1>
          </Col>
        </Row>
        <div className="dashboard-links">
          <Container>
            <Row>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/compliance">
                  <AnimatedButton icon={faCheck}>
                    {t('App.compliance')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/enterprise">
                  <AnimatedButton icon={faStore}>
                    {t('App.enterpriseManagement')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/users">
                  <AnimatedButton icon={faUser}>
                    {t('App.usersManagement')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/enterprises">
                  <AnimatedButton icon={faSync}>
                    {t('App.enterpriseSync')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/ai_compliance_checker">
                  <AnimatedButton icon={faRobot}>
                    {t('App.aiComplianceChecker')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/customers/configs">
                  <AnimatedButton icon={faTasks}>
                    {t('App.connectionConfigs')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/invoices">
                  <AnimatedButton icon={faFileInvoiceDollar}>
                    {t('App.invoices')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/stats">
                  <AnimatedButton icon={faChartLine}>
                    {t('App.stats')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/contract">
                  <AnimatedButton icon={faFileContract}>
                    {t('App.contractsManagement')}
                  </AnimatedButton>
                </Link>
              </Col>
              <Col xs={12} md={6} className="mb-5">
                <Link to="/broadcast">
                  <AnimatedButton icon={faCalendar}>
                    {t('App.broadcast')}
                  </AnimatedButton>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
